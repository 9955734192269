<template>
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
        <a-row :gutter="8">
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="名称">
              <a-input v-model="searchData.name" placeholder="名称" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="有效时间">
              <DateRange :startTime.sync="searchData.startTime" :endTime.sync="searchData.endTime"></DateRange>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="审核状态">
              <DictSelect
                field="reviewStatus"
                :value.sync="searchData.authStatus"
                style="width: 100%"
                placeholder="审核状态"
              ></DictSelect>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="参与积分">
              <DictSelect
                field="IntegralType"
                :value.sync="searchData.joinIntegral"
                style="width: 100%"
                placeholder="参与积分"
              ></DictSelect>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="参与折扣">
              <DictSelect
                field="IntegralType"
                :value.sync="searchData.joinRebate"
                style="width: 100%"
                placeholder="参与折扣"
              ></DictSelect>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <a-button v-if="isAdd" type="primary" @click="toHandler('add')">新增</a-button>
        <a-button v-if="isEdit && selectedRows.length == 1 && selectedRows[0].authStatus != 3" type="primary" @click="toHandler('edit')">修改</a-button>
        <a-button v-if="isDelete" type="primary" @click="toHandler('del')">删除</a-button>
        <a-button v-if="isCheck" type="primary" @click="handleView">查看政策</a-button>
        <a-button v-if="selectedRows.length == 1 && selectedRows[0].authStatus == 3" type="primary" @click="handleAuthagain" >返审</a-button>
<!--        <a-button-->
<!--          type="primary"-->
<!--          @click="handleAuth(selectedRows[0].id, 1)"-->
<!--          v-if="isSubmit && selectedRows.length == 1 && selectedRows[0].authStatus == 1 && !selectedRows[0].closeStatus"-->
<!--          >提交审核</a-button-->
<!--        >-->
        <a-button
          v-if="isSubmit && selectedRows.length >= 1 && selectedRows[0].authStatus == 1 && !selectedRows[0].closeStatus"
          type="primary"
          @click="batchSubmitAudit"
        >提交审核</a-button>
        <a-button
          type="primary"
          @click="
            authReason = ''
            closeConfirmIsView = 1
            authVisible = true
            authAndCloseRowId = selectedRows[0].taskId
          "
          v-if="isAudit && selectedRows.length == 1 && selectedRows[0].authStatus == 2 && !selectedRows[0].closeStatus && selectedRows[0].taskId != null"
          >政策审核</a-button
        >
        <a-button
          v-if="isAudit && selectedRows.length > 1 && selectedRows[0].authStatus == 2 && selectedRows[0].taskId != null"
          type="primary"
          @click="toHandlerAll"
          >批量审核政策</a-button
        >
        <a-button
          type="primary"
          @click="
            closeReason = ''
            closeVisible = true
            authAndCloseRowId = selectedRows[0].id
          "
          v-if="isEnd && selectedRows.length == 1 && selectedRows[0].authStatus == 3 && !selectedRows[0].closeStatus"
          >政策结束</a-button
        >
        <a-button
          type="primary"
          @click="handleAuth(selectedRows[0].id, 4)"
          v-if="selectedRows.length == 1 && selectedRows[0].authStatus == 4 && !selectedRows[0].closeStatus"
          >驳回原因</a-button
        >
        <a-button
          type="primary"
          @click="handleAuth(selectedRows[0].id, 5)"
          v-if="selectedRows.length == 1 && selectedRows[0].closeStatus"
          >结束原因</a-button
        >
      </div>

      <!-- 数据表格 -->
      <a-table
        :loading="tableLoading"
        :row-selection="rowSelection"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="page"
        @change="changeTable"
        :customRow="changeTableRow"
        :rowClassName="rowClassName"
        :scroll="{ x: 1600 }"
      >
        <span slot="validityStart" slot-scope="text, row"> {{ row.validityStart }} ~ {{ row.validityEnd }} </span>
        <span slot="joinRebate" slot-scope="text">
          <span v-if="text"><a-icon type="check-circle" style="color: green" theme="filled" /></span>
          <span v-else><a-icon type="close-circle" style="color: red" theme="filled" /> </span>
        </span>
        <span slot="joinIntegral" slot-scope="text">
          <span v-if="text"><a-icon type="check-circle" style="color: green" theme="filled" /></span>
          <span v-else><a-icon type="close-circle" style="color: red" theme="filled" /> </span>
        </span>
        <span slot="authStatus" slot-scope="text">
          <span v-if="text == 1">未处理</span>
          <span v-if="text == 2">待审核</span>
          <span v-if="text == 3">审核通过</span>
          <span v-if="text == 4">驳回</span>
        </span>
      </a-table>

      <!-- 编辑表单 -->
      <PolicyInfo-edit-modal ref="PolicyInfoEditModal" @reload="getData"></PolicyInfo-edit-modal>

      <a-modal
        :title="closeConfirmIsView == 1 ? '审核' : closeConfirmIsView == 2 ? '驳回' : '结束'"
        width="30%"
        :visible="authVisible"
        :confirm-loading="authConfirmLoading"
        :footer="null"
        @cancel="authVisible = false"
        :maskClosable="false"
      >
        <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
          <a-form-model-item label="原因">
            <a-textarea v-model="authReason"></a-textarea>
          </a-form-model-item>
        </a-form-model>
        <div class="footer-bts">
          <a-button type="default" @click="authVisible = false">取消</a-button>
          <a-button type="danger" @click="handleAuth(authAndCloseRowId, 2, false)" v-if="closeConfirmIsView == 1"
            >拒绝</a-button
          >
          <a-button type="primary" @click="handleAuth(authAndCloseRowId, 2, true)" v-if="closeConfirmIsView == 1"
            >通过</a-button
          >
        </div>
      </a-modal>

      <a-modal
        title="关闭"
        width="30%"
        :visible="closeVisible"
        :confirm-loading="closeConfirmLoading"
        :footer="null"
        @cancel="closeVisible = false"
        :maskClosable="false"
      >
        <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
          <a-form-model-item label="原因">
            <a-textarea v-model="closeReason"></a-textarea>
          </a-form-model-item>
        </a-form-model>
        <div class="footer-bts">
          <a-button type="default" @click="closeVisible = false">取消</a-button>
          <!-- <a-button type="danger" @click="onAudit('refuse')">拒绝</a-button> -->
          <a-button type="primary" @click="handleAuth(authAndCloseRowId, 3)">确定</a-button>
        </div>
      </a-modal>

      <a-modal
        v-model="visibleAll"
        title="批量政策审核"
        @ok="handleOkAll"
        @cancel="handleCanceAll"
        :maskClosable="false"
        width="40%"
      >
        <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 15 }">
          <a-form-model-item label="是否通过">
            <a-radio-group v-model="statusAll">
              <a-radio :value="4"> 通过 </a-radio>
              <a-radio :value="3"> 拒绝 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="备注">
            <a-textarea v-model="rejectReason"></a-textarea>
          </a-form-model-item>
        </a-form-model>
      </a-modal>

      <a-modal
        v-model="visiblePolicy"
        title="返审"
        :footer="null"
        :maskClosable="false"
        width="50%"
      >
        <a-table
          :rowKey="(record) => record.id"
          :columns="columnsPolicy"
          :data-source="tableDataPolicy"
          bordered
          :pagination="false"
          :rowClassName="rowClassName"
        >
        <span slot="orderStatus" slot-scope="text,row">
           <span v-if="row.orderStatus == 0">待审核</span>
           <span v-if="row.orderStatus == 4">已审核待发货</span>
           <span v-if="row.orderStatus == 5">部分发货</span>
           <span v-if="row.orderStatus == 9">草稿</span>
        </span>
        </a-table>
      </a-modal>
    </div>
  </a-card>
</template>

<script>
import { columns } from './components/colums.js'
import PolicyInfoEditModal from './components/PolicyInfoEditModal.vue'
import {
  delPolicyInfo,
  listPolicyInfo,
  selectByIdPolicyInfo,
  authPolicyInfo,
  closePolicyInfo,
  getDismissTheReason,
  getCloseTheReason,
} from './api/PolicyInfoApi'
import { checkPermission } from '@/utils/permissions'
import {auditTask, auditTaskBatch} from "@/api/activiti";

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'policyInfo',
  components: {
    PolicyInfoEditModal,
  },
  data() {
    return {
      columns: columns,
      statusAll: 4,
      rejectReason: '',
      visibleAll: false,
      closeReason: '',
      authReason: '',
      authAndCloseRowId: 0,
      authConfirmLoading: false,
      authVisible: false,
      closeVisible: false,
      closeConfirmLoading: false,
      closeConfirmIsView: 1,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      isAdd: checkPermission('policy:info:add'),
      isEdit: checkPermission('policy:info:edit'),
      isDelete: checkPermission('policy:info:delete'),
      isCheck: checkPermission('policy:info:check'),
      isSubmit: checkPermission('policy:info:submit'),
      isAudit: checkPermission('policy:info:audit'),
      isEnd: checkPermission('policy:info:end'),
      selectedRowKeys: [],
      selectedRows: [],
      dataList: [],
      policyList: [],
      tableDataPolicy: [],
      columnsPolicy: [
        {
          title: '订单号',
          dataIndex: 'orderCode',
          key: 'orderCode',
          align: 'center',
          ellipsis: true,
        },
         {
          title: '订单状态',
          dataIndex: 'orderStatus',
          key: 'orderStatus',
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'orderStatus'}
        },
      ],
      visiblePolicy: false,
      policyName: '返审',
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        // type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {

    /**
     * 批量提交审核
     */
    batchSubmitAudit() {
      var _this = this
      const everyResult = this.selectedRows.every((item) => {
        return item.authStatus === 1
      })
      if (everyResult) {
        this.dataList = this.selectedRows
        _this.$confirm({
          title: '提交审核',
          content: '确认要提交审核吗?',
          okText: '确认',
          cancelText: '取消',
          async onOk() {
            _this.tableLoading  = true
            const query = {
              ids : _this.dataList.map((item) => item.id)
            }
            _this.axios.post('/api/order/policy/policyInfo/batchSubmitAudit', query).then(res => {
              if(res.code == 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              }else {
                _this.$notification.error({ message: res.message })
                _this.getData()
              }

            })
          },
          onCancel() {
          }
        })
      }else{
        this.$notification.error({ message: '请选择未提交审核记录' })
      }
    },

    // 返审
    handleAuthagain() {
      this.axios.get(`/api/order/order/orderInfo/listByPolicyId/${this.selectedRows[0].id}`).then((res) => {
        let policyList = res.body || []
         if (policyList.length === 0) {
        const _this = this
        _this.$confirm({
          title: '返审警告',
          content: '确认要返审此条记录吗?',
          okText: '确认',
          cancelText: '取消',
          onOk() {
            _this.axios.post(`/api/order/policy/policyInfo/status/${_this.selectedRows[0].id}`).then((res) => {
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            })
          },
          onCancel() {},
        })
      } else {
        this.visiblePolicy = true
        this.policyName = '查看返审记录'
        this.tableDataPolicy = policyList
      }
      })

    },
    // 批量审核弹框
    toHandlerAll() {
      let everyResult = this.selectedRows.every((item) => {
        return item.authStatus === 2 && item.taskId != null
      })
      if (everyResult) {
        this.visibleAll = true
        this.dataList = this.selectedRows
      } else {
        this.$notification.error({ message: '请选择相同待审核记录' })
      }
    },
    // 现替换成审批流方法
    // handleOkAll() {
    //   let params = {
    //     authStatus: this.statusAll,
    //     verifyRemark: this.rejectReason,
    //   }
    //   params.ids = this.dataList.map((item) => item.id)
    //   console.log(params)
    //   this.axios.post('/api/order/policy/policyInfo/authList', params).then((res) => {
    //     if (res.code == 200) {
    //       this.$message.success(res.message)
    //       this.visibleAll = false
    //       this.getData()
    //     }
    //   })
    // },
    handleOkAll() {
      const _this = this
      let result = 2
      if (_this.statusAll == 3) {
        result = 3
      }
      auditTaskBatch({ taskIds: _this.dataList.map((item) => item.taskId), result: result ,comment: _this.rejectReason }).then((res) => {
        if (res.code == 200) {
          _this.$message.success('审核成功')
          _this.getData()
        }else {
          _this.$message.error(res.message)
        }
        _this.visibleAll = false
      })
    },
    handleCanceAll() {
      this.visibleAll = false
      this.statusAll = ''
      this.rejectReason = ''
    },
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },

    //处理审核 flag 1:提交审核 2:政策审核 3:政策结束 4:驳回原因
    handleAuth(id, flag, authStatus) {
      console.log('id=' + id + '&flag=' + flag + '&authStatus=' + authStatus)
      const _this = this
      var params = 'policyId=' + id
      switch (flag) {
        case 1:
          params += '&authStatus=' + 2
          authPolicyInfo(params).then((res) => {
            if (res.code === 200) {
              _this.$notification.success({ message: res.message })
              _this.getDataFilter()
            }
          })
          break
        case 2:
          // 现替换成审批流方法
          // if (authStatus == true) {
          //   params += '&authStatus=' + 3
          // } else if (authStatus == false) {
          //   params += '&authStatus=' + 4 + '&dismissTheReason=' + _this.authReason
          // }
          // authPolicyInfo(params).then((res) => {
          //   if (res.code === 200) {
          //     _this.$notification.success({ message: res.message })
          //     _this.getDataFilter()
          //   }
          // })
          let result = 2
          if (authStatus == false) {
            result = 3
          }
          const query = {
            taskId: id,
            result: result,
            comment: _this.authReason
          }
          auditTask(query).then((res) => {
            if (res.code === 200) {
              _this.$message.success('审核成功')
              _this.getDataFilter()
            }
          })
          _this.authVisible = false
          _this.authReason = ''
          break
        case 3:
          params += '&closeReason=' + _this.closeReason
          closePolicyInfo(params).then((res) => {
            if (res.code === 200) {
              _this.$notification.success({ message: res.message })
              _this.getDataFilter()
            }
          })
          _this.closeVisible = false
          _this.closeReason = ''
          break
        case 4:
          getDismissTheReason(params).then((res) => {
            if (res.code === 200) {
              _this.closeConfirmIsView = 2
              _this.authVisible = true
              _this.authReason = res.body
            }
          })
          break
        case 5:
          getCloseTheReason(params).then((res) => {
            if (res.code === 200) {
              _this.closeConfirmIsView = 3
              _this.authVisible = true
              _this.authReason = res.body
            }
          })
          break
      }
    },
    //查看政策
    handleView() {
      if (this.selectedRows.length == 0 || this.selectedRows.length > 1) {
        this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      this.$refs.PolicyInfoEditModal.setRowData(this.selectedRows[0], 'check')
    },
    /**
     * 获取表格数据
     */
    getData() {
      this.selectedRowKeys = []
      this.selectedRows = []
      this.tableLoading = true
      listPolicyInfo({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page.current = 1
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.PolicyInfoEditModal.setRowData({}, 'add')
        return
      }
      if (_this.selectedRows.length == 0 || _this.selectedRows.length > 1) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }

      switch (name) {
        case 'edit':
          _this.$refs.PolicyInfoEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delPolicyInfo(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
      }
    },
  },
  created() {
    this.getData()
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #e9e9e9;
}
</style>
